<template>
  <div>
    <Header />
    <Search />
    <div class="title">
      <div class="w-1200">
        <div class="pingjia">评价</div>
        <div class="neirong">愉快的写下您对宝贝的使用心得~</div>
      </div>
    </div>
    <div class="content1 w-1920">
      <Loading v-if="isLoading" />
      <div>
        <div class="top w-1200">
          <div class="jieshao">
            <div class="img">
              <Uimage :src="list.defaultImg" alt="" />
            </div>
            <div class="shuo">
              {{ list.skuName }}
              <!-- {{ list.name }} -->
            </div>
            <div class="price">￥{{ list.skuRealPrice }}</div>
            <!-- <div class="price">￥{{ list.salePrice }}</div> -->
            <!-- <div class="biao">{{ list.decript }}</div> -->
          </div>
          <div class="ping">
            <div class="wuliu">
              <div class="dafen">请为物流打分</div>
              <div class="xing">
                <el-rate v-model="value1" :colors="colors1"></el-rate>
              </div>
            </div>
            <div class="shangpin">
              <div class="wuliu">
                <div class="dafen">请为商品打分</div>
                <div class="xing">
                  <el-rate v-model="value2" :colors="colors2"></el-rate>
                </div>
              </div>
            </div>
            <div class="tishi">请填写评价内容</div>
            <div class="input">
              <el-input
                type="textarea"
                placeholder="写写您的感受吧"
                v-model="textarea"
                maxlength="500"
                show-word-limit
                :rows="10"
              >
              </el-input>
            </div>
            <div class="shangchuan">
              <el-upload
                :action="picurl"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                :limit="9"
                class="upload"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </div>
        </div>
        <div class="bottom w-1200">
          <div class="fabiao" @click="tijiao">发表</div>
          <el-checkbox v-model="checked">匿名评价</el-checkbox>
        </div>
      </div>
    </div>
    <div class="kongbai"></div>
    <!-- <Baoyou /> -->
    <Foot />
  </div>
</template>
<script>
import { post, get } from '@/utils/request';
import Header from '@/components/header.vue';
import Foot from '@/components/foot.vue';
import Search from '@/components/order/search.vue';
import Baoyou from '@/components/order/baoyou.vue';
import { baseURL } from '@/utils/request';
export default {
  components: {
    Header,
    Foot,
    Search,
    Baoyou,
  },
  data() {
    return {
      checked: false,
      value1: '',
      value2: '',
      colors1: ['#2f62fc', '#2f62fc', '#2f62fc'],
      colors2: ['#2f62fc', '#2f62fc', '#2f62fc'],
      textarea: '',
      dialogImageUrl: '',
      dialogVisible: false,
      isLoading: true,
      list: '',
      ip: '',
      picurl: baseURL + 'f/api/frontCommon/upload',
      coverImg: [],
      batchNumber:''
    };
  },
  created() {
    this.batchNumber=this.$route.params.batchNumber
    this.getdetail(this.$route.params.skuId, this.$route.params.orderId);
    this.ready();
  },
  methods: {
    //获取ip
    ready() {
      this.ip = returnCitySN['cip'];
    },
    getdetail(e, t) {
      get('api/orderbasic/getorderskuitem?skuId=' + e + '&orderId=' + t + '').then(
        (res) => {
          this.list = res.data.data;
          this.isLoading = false;
        }
      );
    },
    tijiao() {
      post('api/orderbasic/orderSpuComment', {
        spuId:this.list.spuId,
        skuId: this.list.skuId,
        storeId: this.list.storeId,
        orderId: this.$route.params.orderId,
        memberIp: this.ip,
        //评论会员ID
        //replyMemberId: this.list.replyMemberId,
        resources: this.coverImg.toString(),
        content: this.textarea,
        logistics: this.value1,
        commodity: this.value2,
        commentType: 0,
        batchNumber:this.batchNumber
      }).then((res) => {
        if (res.data.msg == '操作成功') {
          this.$message.success({
            message: '评价成功',
          });
          this.$router.go(-1);
        } else {
          this.$message.error({
            message: '评价失败',
          });
        }
      });
    },
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === 'image/jpeg' ||
        'image/jpg' ||
        'image/webp' ||
        'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error({
          message: '图片格式不正确!(只能包含jpg，png，webp，JPEG)',
        });
      }
      if (!isLt2M) {
        this.$message.error({
          message: '上传图片大小不能超过 2MB!',
        });
      }
      return isJPG && isLt2M;
    },
    handleRemove(file, fileList) {
      this.coverImg = [];
      fileList.forEach((res) => {
        this.coverImg.push(res.response.fileName);
      });
    },
    handleAvatarSuccess(res, file) {
      this.coverImg.push(res.fileName);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  width: 100%;
  height: 85px;
  background: #f5f5f5;
  padding-top: 10px;
  .pingjia {
    font-weight: 600;
    font-size: 24px;
    color: #282828;
    text-align: center;
  }
  .neirong {
    width: 100%;
    font-size: 14px;
    color: #666;
    text-align: center;
    margin-top: 5px;
  }
}
.content1 {
  width: 100%;
  background: #f5f5f5;
  .top {
    width: 1174px;
    margin: 0 auto;
    background: #fff;
    padding-top: 30px;
    padding-left: 60px;
    padding-bottom: 50px;
    display: flex;
    .jieshao {
      width: 252px;
      height: 100%;
      border-right: 2px solid #f2f2f2;
      .img {
        width: 185px;
        height: 160px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .shuo {
        text-align: center;
        width: 185px;
        font-size: 12px;
        color: #7e7e7e;
        margin-top: 20px;
      }
      .price {
        color: #333;
        font-size: 13px;
        width: 185px;
        text-align: center;
        margin-top: 20px;
      }
      .biao {
        color: #a0a0a0;
        font-size: 12px;
        width: 185px;
        text-align: center;
        margin-top: 15px;
        padding: 0;
      }
    }
    .ping {
      padding-left: 58px;
      width: 920px;
      .wuliu {
        font-size: 15px;
        color: #1850ff;
        display: flex;
        .xing {
          margin-left: 30px;
        }
      }
      .shangpin {
        margin-top: 20px;
      }
      .tishi {
        margin-top: 20px;
        color: #333;
      }
      .input {
        margin-top: 5px;
        padding-right: 40px;
      }
      .shangchuan {
        margin-top: 10px;
        padding: 0;
      }
    }
  }
  .bottom {
    width: 1234px;
    height: 100px;
    background: #fff;
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .fabiao {
      width: 138px;
      height: 40px;
      background: #1850ff;
      color: #fff;
      line-height: 40px;
      text-align: center;
      border-radius: 5px;
      margin-right: 50px;
    }
  }
}
</style>
